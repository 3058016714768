import React from 'react'
import { Link } from 'gatsby'
// import github from '../img/github-icon.svg'
// import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-blue"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              role="button"
              tabIndex={0}
              onKeyDown={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-center has-text-centered">
              <Link className="n-item" to="/">
                Inicio
              </Link>
              <Link className="n-item" to="/sociedad">
                Sociedad
              </Link>
              <Link className="n-item" to="/noticias">
                Noticias
              </Link>
              <Link className="n-item" to="/conferencias">
                Conferencias
              </Link>
              <Link className="n-item" to="/capacitaciones">
                Capacitación contínua
              </Link>
              <Link className="n-item" to="/nuevos-socios">
                Nuevos Socios
              </Link>
              <Link className="n-item" to="/contacto">
                Contacto
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
